.page-login{
    height: 100%;
    width: 100%;
    background-color: $color-blue;
    background-image: linear-gradient(180deg, $color-blue 10%, $color-blue-dark 100%);
    background-size: cover;
}

.login{
    &-card{
        position: relative;
        display: flex;
        min-width: 0;
        word-wrap: break-word;
        background-color: $color-white;
        background-clip: border-box;
        border-radius: 0.35rem;
        box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
        &__section{
            position: relative;
            width: 100%;
            padding-right: 0.75rem;
            padding-left: 0.75rem;
        }
        &--logo{
            color:aliceblue;
            border-top-left-radius: 0.35rem;
            border-bottom-left-radius: 0.35rem;
            display: none;
            h1{
                margin-bottom: 3.1rem;
            }
            @media (min-width: 992px){
                flex: 0 0 50%;
                max-width: 50%;
                display: flex;
            }
        }
        &--form{
            position: relative;
            width: 100%;
            padding-right: 0rem;
            padding-left: 0rem;
            @media (min-width: 992px){
                flex: 0 0 50%;
                max-width: 50%;
                padding-left: 0.75rem;
            }
        }
    }
    &-form{
        width: 100%;
        margin-right: auto;
        margin-left: auto;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        @media (min-width: 576px){
            max-width: 540px;
        }
        @media (min-width: 768px){
            max-width: 720px;
        }
        @media (min-width: 992px){
            max-width: 960px;
         }
        @media (min-width: 1200px){
            max-width: 1140px;
        }
    }
    &-form__container{
        position: relative;
        width: 100%;
        padding-right: 0.75rem;
        padding-left: 0.75rem;
    }
}
.bg-login-image {
    background-color: $color-red-darker;
}