.user-profile-main{
    position: relative;
    height: 4.375rem;
    display: flex;
    .user-profile{
        padding: 0rem 0.8rem;
        &-name{
            color: $color-white;
        }
        &-pic{
            width: 2rem;
            height: 2rem;
            cursor: pointer;
        }
        .vertical-line{
            width: 0;
            border-right: 1px solid $color-gray-dim;
            height: calc(4.375rem - 2rem);
            margin: auto 1.2rem;
        }
    }

    .profile-dropdown-container{
        position: absolute;
        top: 100%;
        left: auto;
        right: 0;
        width: auto;
        background-color: $color-white;
        background-clip: padding-box;
        border: 1px solid $color-gray-dim;
        border-radius: 0.35rem;
        min-width: 10rem;
        margin: 0.125rem 0 0;
        font-size: 0.85rem;
        color: $color-black-dim;
        z-index: 1000;
        .item-divider-line{
            height: 0;
            overflow: hidden;
            border-top: 1px solid $color-tanned-white;
        }
        .iconText{
            width: 100%;
            padding: 0.25rem 1.5rem;
            white-space: nowrap;
            &-text{
                color: $color-black-dim;
                padding-left: 0.7rem;
            }

        }
        .iconText:hover{
            color: $color-darker;
            background-color: $color-light;
        }
    }
    @media (max-width: 576px){
        position: static;
        .profile-dropdown-container{
            width: calc(100% - 1.5rem);
            right: 0.75rem;
        }
    }
}
