
.animation-grow{
    animation-name: grow;
    animation-duration: 250ms;
    animation-timing-function: transform cubic-bezier(0.18, 1.25, 0.4, 1), opacity cubic-bezier(0, 1, 0.4, 1);
}

.animation-fade{
    animation: fadeIn 0.15s linear;
}


@keyframes grow {
    from{
        transform: scale(0.5);
        opacity: 0;
    }
    to{
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
 } 