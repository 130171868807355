.app-logo{
    padding: 0.8125rem 1rem;
    &-img{
        width: 1.9rem;
        img{
            width: 100%;
            display: block;
        }
    }
    &-title{
        font-size: 1.25rem;
        line-height: inherit;
        white-space: nowrap;
        color: $color-black-faded;
    }
}