.tooltip-inner {
    background-color: #EB3C31 !important;;
}

.tooltip.bs-tooltip-right .arrow:before {
    border-right-color: #EB3C31 !important;
}

.tooltip.bs-tooltip-left .arrow:before {
    border-left-color: #EB3C31 !important;
}

.tooltip.bs-tooltip-bottom .arrow:before {
    border-bottom-color: #EB3C31 !important;
}

.tooltip.bs-tooltip-top .arrow:before {
    border-top-color: #EB3C31 !important;
}