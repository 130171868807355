.shadow{
    box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important;
}
.shadow-lg {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}
.m-20px{
    margin:20px;
}
.m-0{
    margin: 0;
}
.mb-0{
    margin-bottom: 0;
}
.mb-4{
    margin-bottom: 1.5rem;
}
.mb-3{
    margin-bottom: 3rem;
}

.pt-3{
    padding-top: 3rem;
}

.pb-3{
    padding-bottom: 3rem;
}

.p-3{
    padding: 3rem;
}

.mb-1{
    margin-bottom: 1rem;
}

.mt-1{
    margin-top: 1rem;
}

.mt-3{
    margin-top: 3rem;
}

.mr-3{
    margin-right: 3rem;
}

.ml-auto{
    margin-left: auto;
}
.text-gray-800{
    color: $color-gray-dark !important;
}
.text-gray-400 {
    color: $color-gray-light;
}
.text-gray-900{
    color:#3a3b45;
}

.text-red{
    color: #FF0000;
}

.h3{
    font-size: 1.75rem;
    font-weight: 400;
    line-height: 1.2;
}
.h1 {
    font-size: 2.5rem;

    margin-bottom: 0.5rem;
    font-weight: 400;
    line-height: 1.2;
}
.flex-align-center{
    display: flex;
    align-items: center;
}
.flex-align-end{
    display: flex;
    align-items: center;
}
.flex-align-start{
    display: flex;
    align-items: center;
}
.justify-space-bw{
    justify-content: space-between;
}

.justify-space-around{
    justify-content: space-around;
}

.justify-space-even{
    justify-content: space-evenly;
}

.justify-center{
    justify-content: center;
}


.padding-all-3{
    padding: 3rem;
}
.padding-all-1{
    padding: 1rem;
}

.padding-all-05{
    padding: 0.5rem;
}

.padding-lr-min{
    padding: 0rem 0.25rem;
}

.padding-lr-med{
    padding: 0rem 0.5rem;
}

.padding-lr-1{
    padding: 0rem 1rem;
}

.padding-lr-075{
    padding: 0rem 0.75rem;
}

.padding-tb-med{
    padding: 0.5rem 0; 
}

.padding-tb-2{
    padding: 2rem 0rem;
}

.padding-l-min{
    padding-left: 0.25rem;
}

.margin-right-1{
    margin-right: 1rem;
}

.margin-tb-med{
    margin: 0.5rem 0;
}

.border-radius-50{
    border-radius: 50%;
}

.full-width-img{
    width: 100%;
    height: 100%;
}

.font-size-small{
    font-size: 0.8rem;
}

.fa-width{
    text-align: center;
    width: 1.25em;
}

.fa-small{
    font-size: .875em;
}

.font-weight-400{
    font-weight: 400;
}

.text-left{
    text-align: left;
}

.bg-white{
    background-color: #fff;
}

.bg-hv{
    background-color: #3d3838;
}

.bg-red{
    background-color: #FF0000;
}

.block-display{
    display: block;
}

.hide{
    display: none;
}

.wrap-flex{
    flex-wrap: wrap;
}

.no-wrap-flex{
    flex-wrap: nowrap;
}

.transparent-border-1{
    border: 1px solid transparent;
}

.cursor-ptr{
    cursor: pointer;
}
.width-100{
    width: 100%;
}

.width-50{
    width: 50%;
}

.center-text{
    text-align: center;
}

.flex-row{
    display: flex;
    flex-wrap: wrap;
    margin-right: -0.75rem;
    margin-left: -0.75rem;
}

.min-flex-division{
    @media (min-width: 576px){
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }
}

.max-flex-division{
    @media (min-width: 576px){
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }
}