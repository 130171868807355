@import './../abstract/variable';

.search-result {
    .page-item.active {
        .page-link {
            z-index: 3;
            color: #fff;
            background-color: $theme-primary;
            border-color: $theme-primary
        }
    }


}

.react-bootstrap-table {
    table {
        thead {
            tr {
                th {
                    font-size: 0.9rem;
                    padding: 0.5rem 0.5rem;
                    vertical-align: top;
                
                }
            }
        }

        tr {
            td {
                font-size: 0.9rem;
                padding: 0.5rem 0.5rem;
            }
        }
    }

}