.form-input{
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: $color-black-lighter;
    background-color: $color-white;
    background-clip: padding-box;
    border: 1px solid $color-gray-light;
    border-radius: 0.35rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    font-family: inherit;
    &:focus{
        color: $color-black-lighter;
        background-color: $color-white;
        border-color: $color-primary-ligher;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
    }

    &--user{
        font-size: 0.8rem;
        border-radius: 10rem;
        padding: 1.5rem 1rem;
    }
}

.labeled-input{
    margin-bottom: 1rem;
    margin-right: -0.75rem;
    margin-left: -0.75rem;
    &__label{
        padding-top: calc(0.375rem + 1px);
        padding-bottom: calc(0.375rem + 1px);
        margin-bottom: 0;
        font-size: inherit;
        color: $color-secondary;
    }
}

.btn {
    display: inline-block;
    font-weight: 400;
    color: $color-secondary;
    text-align: center;
    vertical-align: middle;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.35rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    font-family: inherit;
    &:hover{
        color: $color-white;
        background-color: $color-primary-dark;
        border-color: $color-primary-darker;
    }
    &:focus{
        color: $color-white;
        background-color: $color-primary-darker;
        border-color: $color-blue-darker;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(105, 136, 228, 0.5)
    }
}

.btn-primary {
    color: $color-white;
    background-color: $color-primary;
    border-color: $color-primary;
}
.btn-secondary {
    color: $color-white;
    background-color: $color-secondary;
    border-color: $color-secondary;
    &:hover{
        background-color: #717384;
        border-color: #6b6d7d;
    }
    &:focus{
        box-shadow: 0 0 0 0.2rem rgba(151, 153, 166, 0.5);
    }
}
.btn-block {
    display: block;
    width: 100%;
}
.btn-user {
    font-size: 0.8rem;
    border-radius: 10rem;
    padding: 0.75rem 1rem;
}


.button-container{
    margin-right: -0.75rem;
    margin-left: -0.75rem;
}

@media (min-width: 768px){
    .btn-offset{
        margin-left: 25%;
    }
    .btn-col-3{
        flex: 0 0 25%;
        max-width: 25%;
    }
}

.control-label {
    text-transform: uppercase !important;
}

.form-group.required {
    .control-label:after {
        content: "*";
        color: red;
        margin-left: 3px;
    }
}
.display-linebreak {
    white-space: pre-line;
}

