@font-face {
    font-family: 'Nunito';
    src: url('https://fonts.googleapis.com/css?family=Nunito:200,200i,300,300i,400,400i,600,600i,700,700i,800,800i,900,900i') format('truetype');

}
html {
    color: $color-secondary;
    box-sizing: border-box;
    height: 100%;
    width: 100%;

  }
  body{
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    line-height: 1.5;
    font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    // padding-bottom: 1.5rem !important;
    min-height: 100% !important;
    position: relative !important;
  }
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
  
  a{
    &, &:visited, &:active, &:-webkit-any-link{
      text-decoration: none;
    }
  }

  img{
    display: block;
  }

  #root{
    height: 100%;
  }