.login-card{
    &--form{
        &-title{
            h1{
                font-size: 1.5rem !important;
                font-weight: 400 !important;
                line-height: 1.2;
            } 
        }
        .custom-control {
            position: relative;
            display: block;
            min-height: 1.5rem;
            padding-left: 1.5rem;
            color: $color-secondary;
        }
        hr{
            border: 0;
            border-top: 1px solid rgba(0, 0, 0, 0.1);
        }
        .small {
            font-size: 80%;
            font-weight: 400;
        }
        .custom-control-input {
            position: absolute;
            z-index: -1;
            opacity: 0;
        }

        .custom-control-label {
            position: relative;
            margin-bottom: 0;
            vertical-align: top;
            line-height: 1.5rem;
            display: inline-block;
        }
        .custom-checkbox .custom-control-label::before {
            border-radius: 0.35rem;
        }
        
        .custom-control-label::before {
            position: absolute;
            top: 0.25rem;
            left: -1.5rem;
            display: block;
            width: 1rem;
            height: 1rem;
            pointer-events: none;
            content: "";
            background-color: $color-white;
            border: 1px solid #b7b9cc;
            transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        }

       


        .custom-control-input:checked~.custom-control-label::before {
             color: $color-white;
             border-color: $color-primary;
             background-color: $color-primary;
             outline: 0;
        }

        .custom-control-input:focus~.custom-control-label::before {
            box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
        }

        .custom-control-label::before, .custom-file-label, .custom-select {
            transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        }
        
        .custom-control-label::after {
            position: absolute;
            top: 0.25rem;
            left: -1.5rem;
            display: block;
            width: 1rem;
            height: 1rem;
            content: "";
            background: no-repeat 50% / 50% 50%;
        }
        .custom-checkbox .custom-control-input:checked~.custom-control-label::after {
            background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA4IDgnPjxwYXRoIGZpbGw9JyNmZmYnIGQ9J002LjU2NC43NWwtMy41OSAzLjYxMi0xLjUzOC0xLjU1TDAgNC4yNiAyLjk3NCA3LjI1IDggMi4xOTN6Jy8+PC9zdmc+");
        }
    }
}

@media (min-width: 768px){
    .login-card-md{
        flex: 0 0 75%;
        max-width: 75%;
    }
}
@media (min-width: 992px){
    .login-card-lg {
        flex: 0 0 100%;
        max-width: 100%;
    }
}
@media (min-width: 1200px){
    .login-card-xl {
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }
}
