.home-page {
    background-color: $color-light;

    &__container {
        height: calc(100vh - 117px);
    }


    &__header {
        color: $color-secondary;
        font-size: 0.8rem;
        top: 0 !important;
        margin: auto !important;
    
        position: fixed;
        z-index: 1000;
        // padding-left: 15px !important;
        // padding-right:150px !important;
        margin-right: 0px !important;
        margin-left: -15px !important;
        & .header {
            text-align: center;
            padding-left: 0px !important;
            padding-right: 0px !important;
            margin-right: 0px !important;
            margin-left: 0px !important;
            border-bottom: 5px solid #eb3c31;
        }
    }

    &__main {
        margin-top: 70px;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        padding-bottom: 3.4rem;
        min-height: calc(100%) !important;

    }

    &__footer {
        color: $color-secondary;
        // background-color: $color-red-darker;
        text-align: center;
        font-size: 0.8rem;
        color: $color-white;

        // bottom: 0;
        .footer-logo {
            width: 90px;
            padding: 10px;
        }

        footer {
            background: $theme-blk;
            border-top: 5px solid #8A8A8B;
            width: 100%;
        }
    }
}