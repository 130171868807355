$theme-primary: #EB3C31;
$theme-secondary: #9a231c;
$theme-white: #FFF;
$theme-dk-gray: #333;
$theme-blk: #272727;

a { 
  color: $theme-secondary;
  font-weight: bold;
}
a:hover{
  color: $theme-primary;
  text-decoration: underline;
}
  .user-profile-main .user-profile-name.font-dark{
    color: $theme-dk-gray;
  }
  .navbar .user-profile-main{
    height:1rem;
  }
  .home-page .navbar{
    padding: 0;
  }
  .navbar{
    padding: 0px;
  }
  .navbar .app-logo{
    padding: 0;
  }
  .navbar .app-logo-img{
    background:$theme-primary;
    padding:0;
    width:50px;
  }
  .navbar .app-logo-img img{
    padding: 6px;
  }
  .navbar .page-title{
    margin: 8px 0px 0px 20px;
  }
  .form-group label{
    font-size: .8em;
  }
  .btn.btn-primary{
    background-color: $theme-primary;
    border-color:$theme-primary;
  }
  .btn.btn-primary:hover,
  .btn.btn-primary:active,
  .btn.btn-primary:focus, 
  .btn-primary:not(:disabled):not(.disabled).active, 
  .btn-primary:not(:disabled):not(.disabled):active, 
  .show>.btn-primary.dropdown-toggle{
    background-color: $theme-secondary;
    border-color: $theme-secondary;
  }

  .btn.btn-secondary{
    background-color: $theme-white;
    border-color:$theme-primary;
    color:$theme-primary;
  }
  .btn.btn-secondary:hover,
  .btn.btn-secondary:active,
  .btn.btn-secondary:focus, 
  .btn-secondary:not(:disabled):not(.disabled).active, 
  .btn-secondary:not(:disabled):not(.disabled):active, 
  .show>.btn-secondary.dropdown-toggle{
    background-color: $theme-white;
    border-color:$theme-secondary;
    color:$theme-primary;
  }

  .btn{
    text-transform: uppercase;
    font-weight: bold;
  }
  .search-btns .btn{
    min-width: 150px;
    margin-left:15px;
  }

  .app .page-link{
    color:$theme-primary;
  }

  .search-results .btn.btn-primary{
    letter-spacing: 2px;
    font-size: .6rem;
  }