$color-blue: #4e73df;
$color-blue-dark: #224abe;
$color-indigo: #6610f2;
$color-purple: #6f42c1;
$color-pink: #e83e8c;
$color-red: #e74a3b;
$color-orange: #fd7e14;
$color-yellow: #f6c23e;
$color-green: #1cc88a;
$color-teal: #20c9a6;
$color-cyan: #36b9cc;
$color-white: #fff;
$color-gray: #858796;
$color-gray-dark: #5a5c69;
$color-primary: #4e73df;
$color-primary-light: #c2d2ffb0;
$color-primary-ligher: #bac8f3;
$color-primary-dark: #2e59d9;
$color-primary-darker: #2653d4;
$color-blue-darker: #244ec9;
$color-secondary: #858796;
$color-success: #1cc88a;
$color-info: #36b9cc;
$color-warning: #f6c23e;
$color-danger: #e74a3b;
$color-red-darker: #ce0000;
$color-light: #f8f9fc;
$color-dark: #5a5c69;
$color-black: #010101;
$color-dark-black: #000;
$color-logo: #c1d3f1;
$color-black-faded: #000000e6;
$color-gray-light: #d1d3e2;
$color-gray-dim: #e3e6f0;
$color-black-dim : #3a3b45;
$color-darker: #2e2f37;
$color-black-lighter: #6e707e;
$color-tanned-white: #eaecf4;
$color-semi-light: #dddfeb;

$theme-primary: #EB3C31;
$theme-secondary: #9a231c;
$theme-white: #FFF;
$theme-dk-gray: #333;
$theme-blk: #272727;